<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main bg-pattern-3">
      <!-- Header main -->
      <!--        <HeaderDashboard></HeaderDashboard>-->
      <HeaderMain></HeaderMain>
      <!-- hero -->
    </header>
    <!-- offer section -->
    <section class="user-panel-section " style="padding: 20px 0">
      <div class="row">
        <!-- user sidebar -->
        <UserSidebar :title="offerSidebarData.title" :lists="offerSidebarData.navList"
                     :navs="SectionData.offerTabNavMobile">
        </UserSidebar>
        <!-- offer -->
        <!--                    <OfferSection></OfferSection>-->

        <div class="col-lg-10 ps-xl-5">
          <router-view></router-view>
        </div>
      </div><!-- end row -->
    </section><!-- end user-panel-section -->
    <!-- Footer  -->
    <Footer classname="bg-cus on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import HeaderMain from "@/components/n1/HeaderMain"
import UserSidebar from "@/components/n1/UserSidebar"
import Footer from "@/pages/n1/Footer"

export default {
  name: 'UserPanelLayout',
  components: {
    HeaderMain,
    UserSidebar,
    Footer
  },
  data() {
    return {
      SectionData,
      // offer sidebar data
      offerSidebarData: {
        title: '',
        navList: [
          {
            id: 1,
            class: 'active',
            icon: require('@/images/tmp/report.png'),
            title: 'Dashboard',
            path: '/profile/dashboard'
          },
          {
            id: 2,
            icon: require('@/images/tmp/bill.png'),
            title: 'My Bills',
            path: '/profile/my-bills'
          },
          {
            id: 3,
            icon: require('@/images/tmp/deposit1.png'),
            title: 'Deposit',
            path: '/profile/deposit'
          },
          {
            id: 4,
            icon: require('@/images/tmp/withdraw.png'),
            title: 'Withdraw',
            path: '/profile/withdraw'
          },
          {
            id: 5,
            icon: require('@/images/tmp/checkout.png'),
            title: 'My Orders',
            path: '/profile/my-order'
          },
          // {
          //   id: 6,
          //   icon: 'ni-puzzle',
          //   title: 'CONTRACTS',
          //   path: '/contracts'
          // },
          // {
          //   id: 7,
          //   icon: require('@/images/tmp/affiliate-marketing.png'),
          //   title: 'AFFILIATES',
          //   path: '/profile/affiliates'
          // },
          {
            id: 8,
            icon: require('@/images/tmp/settings.png'),
            title: 'Settings',
            path: '/profile/settings'
          }
        ]
      },
    }
  }
}
</script>

<style scoped>
.bg-cus {
  background-color: #ffe5d0;
}

.row {
  //width: 100%;
  //overflow-x: hidden;
}
</style>
