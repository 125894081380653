<template>
  <div class="col-lg-2">
    <div class="sidebar sidebar-user-mobile sidebar-widget">
        <ul class="user-nav">
          <li v-for="(list, i) in lists" :key="i" :class="{ active: list.path === $route.path }">
            <router-link :to="list.path" class="nav-link">
<!--              <em class="ni me-2" :class="list.icon"></em>-->
              <img class="ni me-2" :src="list.icon"/>
              {{ list.title }}
            </router-link>
          </li>
        </ul>
    </div><!-- end sidebar -->
  </div><!-- end col-lg-2 -->
</template>

<script>
import SectionData from '@/store/store.js'

export default {
  name: 'UserSidebar',
  props: ['lists', 'title', 'navs'],
  data() {
    return {
      SectionData,
    }
  },
  mounted() {
    var sidebarMenuOpen = document.querySelector(".menu-toggler-user-open");
    var userSidebar = document.querySelector(".sidebar-user-mobile");

    if (window.innerWidth <= 991 && !userSidebar.classList.contains('menu-toggler-user-close')) {
      userSidebar.classList.add('menu-toggler-user-close');
    }

    function userSidebarMenu() {
      if (sidebarMenuOpen) {
        sidebarMenuOpen.addEventListener("click", function (e) {
          e.preventDefault();
          userSidebar.classList.add('active');
        });
      }
    }

    userSidebarMenu();

    userSidebar.addEventListener('click', function () {
      this.classList.remove('active');
    })

  }
}
</script>

<style scoped>
/* Custom styles for the user sidebar */
.sidebar {
  background-color: lightyellow; /* Light gray background */
  //background-color: #bfabdf; /* Light gray background */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.sidebar-user-mobile {
  position: relative;
  //background-color: #ffffff;
  width: 290px;
  //z-index: 1010;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  //padding: 10px 20px 20px 20px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.4s;
  transform: translateX(1%);
}

.sidebar-widget {
  padding: 5px;
}

.user-nav {
  list-style: none;
  padding: 0;
}

.user-nav li {
  margin-bottom: 10px;
}

.user-nav li a {
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  color: #333;
}

.user-nav li a:hover {
  background-color: #007bff; /* Primary color on hover */
  color: #fff;
}

.user-nav li:first-child a {
  margin-top: 10px; /* Adjust the top margin for the first menu item */
}

.active a {
  background-color: #007bff; /* Primary color for active item */
  color: #fff;
}

.menu-toggler-user-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: #333;
}


.ni {
  width: 36px;
}

.sidebar{
  width: 230px;
  //height: 420px;
}



@media (max-width: 1200px)  {
  .user-nav li a {
    //font-size: 13px;
  }
  .ni {
    display: none;
    //width: 1px;
  }
  .sidebar{
    width: 165px;
  }
}




</style>

